import React from 'react';
import { useNavigate } from 'react-router-dom';
import StepCard from './StepCard';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateToTimeline = () => {
    navigate('/timeline');
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-blue-900 via-purple-700 to-indigo-900 flex flex-col items-center text-white overflow-hidden">
      {/* Hero Section */}
      <section className="w-full py-32 px-6 sm:px-10 md:px-16 flex flex-col items-center text-center relative z-10">
        <div className="max-w-5xl mx-auto space-y-6">
          <h1 className="text-6xl md:text-7xl font-extrabold leading-tight tracking-tight drop-shadow-lg">
            Profit from the Past
          </h1>
          <p className="mt-4 text-lg md:text-2xl font-light max-w-3xl mx-auto">
            Generate timelines and see how news events impact stock prices.
          </p>
          <button
            onClick={handleNavigateToTimeline}
            className="mt-10 px-10 py-4 bg-white text-blue-600 font-semibold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300"
          >
            Start Now
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section className="w-full py-20 px-6 sm:px-10 md:px-16 relative z-10 bg-white text-gray-900 rounded-t-3xl">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-5xl font-bold mb-16 text-gray-900">
            How It Works
          </h2>
          <div className="grid md:grid-cols-3 gap-12">
            <StepCard
              step="1"
              title="Think of a recurring event"
              description="Type in an event or news story to generate a timeline."
            />
            <StepCard
              step="2"
              title="Analyze Impact"
              description="Visualize how stock prices are affected each time this event happens."
            />
            <StepCard
              step="3"
              title="Make Informed Decisions"
              description="Use historical insights to guide your investment strategies."
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full py-24 px-6 sm:px-10 md:px-16 text-center relative z-10 bg-gradient-to-r from-indigo-700 via-purple-800 to-blue-900">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-5xl font-bold text-white drop-shadow-lg">
            Ready to Make Smarter Investments?
          </h2>
          <button
            onClick={handleNavigateToTimeline}
            className="mt-10 px-10 py-4 bg-white text-blue-600 font-semibold rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300"
          >
            Try for Free
          </button>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
