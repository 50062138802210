import React from 'react';
import {
  HistoricalEvent,
  GetConsequencesRequest,
  GetConsequencesResponse,
} from '../types';
import { getConsequences } from '../services/getConsequences';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from './LoadingSpinner';
import { useState } from 'react';

interface TimelineProps {
  events: HistoricalEvent[];
}

const Timeline: React.FC<TimelineProps> = ({ events }) => {
  const [expandedEventId, setExpandedEventId] = useState<string | null>(null);
  const [consequences, setConsequences] = useState<{
    [key: string]: HistoricalEvent[];
  }>({});
  const [loadingEventId, setLoadingEventId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleLoadConsequences = async (eventId: string) => {
    if (expandedEventId === eventId) {
      setExpandedEventId(null);
      return;
    }

    setLoadingEventId(eventId);
    setError(null);
    setExpandedEventId(eventId);

    try {
      const event = events.find((e) => e.id === eventId);
      if (!event) {
        setError('Selected event not found');
        setLoadingEventId(null);
        return;
      }

      const request: GetConsequencesRequest = { event };
      const response: GetConsequencesResponse = await getConsequences(request);

      setConsequences((prev) => ({
        ...prev,
        [response.eventId]: response.consequences,
      }));
    } catch (err) {
      console.error('Error fetching consequences:', err);
      setError('Failed to fetch consequences. Please try again later.');
    } finally {
      setLoadingEventId(null);
    }
  };

  return (
    <div className="relative max-w-5xl mx-auto mt-16 px-4 lg:px-0">
      {/* Timeline Arrow Line */}
      <div className="absolute left-[15%] transform -translate-x-1/2 w-1 bg-gradient-to-b from-blue-500 to-purple-500 h-full hidden md:block"></div>
      {events.map((event) => (
        <div
          key={event.id}
          className="relative mb-10 flex md:flex-row flex-col items-start md:items-center"
        >
          {/* Date on the Left */}
          <div className="w-full md:w-1/5 text-center md:text-right mb-4 md:mb-0">
            <div className="inline-block bg-white shadow-md rounded-lg px-4 py-2 text-gray-600 text-sm font-semibold border border-gray-100">
              {event.dateUserString}
            </div>
          </div>

          {/* Icon and Timeline Line in the Center for Desktop */}
          <div className="hidden md:flex w-1/12 items-center justify-center relative">
            <div className="w-4 h-4 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full border-2 border-white shadow-md"></div>
          </div>

          {/* Event Content on the Right */}
          <div className="w-full md:w-4/6 bg-white shadow-md rounded-xl px-4 py-4 text-gray-800 text-base font-semibold border border-gray-100 transition-transform transform hover:scale-105 hover:shadow-xl md:ml-4">
            <h3 className="text-lg font-bold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent mb-2">
              {event.title}
            </h3>
            <p className="text-gray-700 text-sm mb-4">{event.description}</p>

            {/* Floating Button for Loading Consequences */}
            <div className="flex justify-end">
              <button
                onClick={() => handleLoadConsequences(event.id)}
                disabled={loadingEventId === event.id}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full flex items-center justify-center shadow-md hover:from-blue-600 hover:to-purple-600 transition-all"
                style={{ width: '42px', height: '42px' }}
              >
                {loadingEventId === event.id ? (
                  <LoadingSpinner size={16} color="#ffffff" />
                ) : (
                  <ArrowRightIcon className="h-5 w-5" />
                )}
              </button>
            </div>

            {/* Consequences Section - Only show if expanded and not loading */}
            {expandedEventId === event.id && !loadingEventId && (
              <div className="mt-4">
                {error && <p className="text-red-500">{error}</p>}
                {consequences[event.id] && consequences[event.id].length > 0 ? (
                  consequences[event.id].map((consequence) => (
                    <div key={consequence.id} className="mb-4 flex items-start">
                      <div className="w-full bg-white shadow-md rounded-lg px-4 py-3 text-gray-700 border border-gray-100">
                        <h4 className="text-lg font-bold mb-2">
                          {consequence.title}
                        </h4>
                        <p className="text-sm">{consequence.description}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No consequences found.</p>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Timeline;
