import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HypothesisCard from './cards/HypothesisCard';
import { HistoricalEvent } from '../types';
import Timeline from './Timeline';

const TimelineApp: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialHypothesis = searchParams.get('query') || '';
  const [hypothesis, setHypothesis] = useState<string>(initialHypothesis);
  const [events, setEvents] = useState<HistoricalEvent[]>([]);

  const handleHypothesisChange = (newHypothesis: string) => {
    setHypothesis(newHypothesis);
    setSearchParams({ query: newHypothesis }); // Update the URL only on user interaction
  };

  return (
    <div className="w-full max-w-7xl mx-auto py-16 px-6">
      {/* Trigger Section */}
      <div className="mb-8">
        <HypothesisCard
          hypothesis={hypothesis}
          setHypothesis={handleHypothesisChange} // Call handler to update both state and URL
          events={events}
          setEvents={setEvents}
        />
      </div>

      {/* Timeline Section (appears below the Trigger) */}
      {events.length > 0 && <Timeline events={events} />}
    </div>
  );
};

export default TimelineApp;
