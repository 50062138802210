import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import TimelineApp from './components/TimelineApp';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <Router>
      <div className="bg-white min-h-screen font-sans">
        <Header />
        <main className="min-h-[calc(100vh-80px)]">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/timeline" element={<TimelineApp />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
