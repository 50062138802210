import React, { useState } from 'react';
import { generateEvents } from '../../services/generateEvents';
import { HistoricalEvent } from '../../types';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from '../LoadingSpinner';
import { Mixpanel } from '../../services/Mixpanel';

interface HypothesisCardProps {
  hypothesis: string;
  setHypothesis: (hypothesis: string) => void;
  events: HistoricalEvent[];
  setEvents: (events: HistoricalEvent[]) => void;
}

const HypothesisCard: React.FC<HypothesisCardProps> = ({
  hypothesis,
  setHypothesis,
  setEvents,
}) => {
  const [localHypothesis, setLocalHypothesis] = useState<string>(hypothesis);
  const [loading, setLoading] = useState<boolean>(false);

  // Suggested phrases
  const suggestedHypotheses = [
    'Elon Musk tweets',
    'Nancy Pelosi buys or sells a stock',
    'McDonalds brings back the McRib',
    'Apple launch event',
    'Netflix releases a show',
  ];

  const handleSave = async () => {
    if (localHypothesis.trim()) {
      setHypothesis(localHypothesis);
      Mixpanel.track('event_search', { query: localHypothesis });
      setLoading(true);
      try {
        const fetchedEvents = await generateEvents({ query: localHypothesis });
        Mixpanel.track('events_fetched', {
          query: localHypothesis,
          events: fetchedEvents.events,
        });
        setEvents(fetchedEvents.events);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChipClick = (suggestedText: string) => {
    setLocalHypothesis(suggestedText);
    setHypothesis(suggestedText); // Immediately update the main hypothesis
    setLoading(true);
    Mixpanel.track('suggestion_clicked', { query: suggestedText });

    // Fetch events based on the chip text
    generateEvents({ query: suggestedText })
      .then((fetchedEvents) => setEvents(fetchedEvents.events))
      .catch((error) => console.error('Error fetching events:', error))
      .finally(() => setLoading(false));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div
      className="bg-white shadow-xl rounded-2xl p-6 transition-transform"
      style={{ minHeight: 'auto' }}
    >
      <h3 className="text-2xl font-semibold mb-4 text-gray-800 tracking-tight">
        Tell us about an event!
      </h3>

      {/* Input and Submit Button */}
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="text"
          placeholder="Type or select a suggestion"
          value={localHypothesis}
          onChange={(e) => setLocalHypothesis(e.target.value)}
          className="flex-grow bg-gray-100 border border-transparent focus:border-blue-400 rounded-full py-2 px-4 transition-all focus:outline-none text-gray-700 placeholder-gray-500 focus:bg-white"
          onKeyDown={handleKeyDown}
        />

        <button
          onClick={handleSave}
          disabled={loading}
          className="flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full p-2 hover:from-blue-600 hover:to-purple-600 transition-all shadow-md"
          style={{ width: '42px', height: '42px' }}
        >
          {loading ? (
            <LoadingSpinner size={20} color="#ffffff" />
          ) : (
            <ArrowRightIcon className="h-5 w-5" />
          )}
        </button>
      </div>

      {/* Suggested Chips Below the Text Box */}
      <div className="flex flex-wrap gap-2 mt-2">
        {suggestedHypotheses.map((suggestion, index) => (
          <button
            key={index}
            onClick={() => handleChipClick(suggestion)}
            className="px-3 py-1 text-sm bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-md transform transition-transform duration-300 hover:scale-105 hover:from-blue-600 hover:to-purple-600"
          >
            {suggestion}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HypothesisCard;
