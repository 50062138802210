import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-gradient-to-r from-blue-900 via-purple-700 to-indigo-900 text-white shadow-md py-3 px-4 sm:py-4 sm:px-6 md:px-8">
      <div className="max-w-6xl mx-auto flex flex-wrap items-center justify-between gap-y-2">
        {/* Logo or Title */}
        <div className="text-2xl sm:text-3xl font-extrabold tracking-tight">
          <a
            href="/"
            className="hover:text-indigo-300 transition-colors duration-300"
          >
            solulu
          </a>
        </div>

        {/* Navigation and User Profile */}
        <nav className="flex flex-wrap items-center gap-4">
          <a href="/timeline" className="inline-block">
            <button className="px-4 sm:px-6 py-1.5 sm:py-2 text-sm sm:text-base bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold uppercase rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 hover:from-blue-600 hover:to-purple-600 hover:shadow-xl whitespace-nowrap">
              New Timeline
            </button>
          </a>

          <a
            href="https://forms.gle/yu2MbDWQoWNLjSaq5"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            <button className="px-4 sm:px-6 py-1.5 sm:py-2 text-sm sm:text-base bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold uppercase rounded-full shadow-lg transform hover:scale-105 transition-transform duration-300 hover:from-green-600 hover:to-teal-600 hover:shadow-xl whitespace-nowrap">
              Give Feedback
            </button>
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
